$(window).on('load', function () {
    $('html').removeClass('no-js');

    if ($(window).width() < 760) {
        $('.footer-menu-trigger').on('click', function(e) {
            e.preventDefault();
            $(this).parents('div.col').find('ul').slideToggle(100);
        });
    }
});

// Mobile menu
$(document).on('click', '.menu-toggler', function (e) {
    e.preventDefault();
    $(this).parents('nav').find('ul').slideToggle(100);
});

// Dropdowns
$(document).on('click', '#menu .menu-btn', function (e) {
    if ($(this).parent('li').find('.dropdown').length) {
        e.preventDefault();
        $('#menu .dropdown').hide();
        if ($(this).hasClass('open')) {
            $(this).removeClass('open');
            $(this).parent('li').find('.dropdown').slideUp(100);
        } else {
            $('#menu .menu-btn').removeClass('open');
            $(this).addClass('open');
            $(this).parent('li').find('.dropdown').slideDown(250);
        }
    }
});

// Print current view
$(document).on('click', 'a.print', function (e) {
    e.preventDefault();
    window.print();
});

// Page galleries
if ($('figure.image').length && $('.hidden-gallery').length) {
    $('figure.image').each(function() {
        var gallery_id = $('.hidden-gallery').data('lg-id');

        $(this).addClass('open-gallery');
        $(this).find('img').wrap('<a href="#/" data-lg-open="' + gallery_id + '"></a>');
    });
}

// Events
$(document).on('change', '.event-controls .current select', function() {
    if ($(this).val() != '') {
        window.location = $(this).data('reload-url') + '?m=' + $(this).val();
    }
});

// News categories
$(document).on('change', '.news-list-title select[name=c]', function() {
    window.location = $(this).data('reload-url') + '?c=' + $(this).val();
});

// Calendar
$(document).on('click', '.next-events .views a', function(e) {
    e.preventDefault();

    $('#event-list, #event-calendar').hide();
    $('.next-events .views a').removeClass('active');
    $(this).addClass('active');
    $('#' + $(this).data('show')).slideDown(100);
});

$(document).on('click', '.next-events .cal-header .cal-direction', function(e) {
    e.preventDefault();
    
    $.ajax({
        url: '/calendar/reload',
        type: 'POST',
        data: { base_date: $(this).data('newdate') }
    })
    .done(function(result) { $('#event-calendar').html(result); })
    .error(function(result) { console.log(result); });
});