// Highlights
if ($('.highlights-slider').length) {
    $('.highlights-slider').slick({
        lazyLoad: 'ondemand',
        dots: false,
        arrows: false,
        infinite: false,
        centerMode: false,
        variableWidth: true,
        slidesToShow: 6,
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    });

    $(document).on('click', '.next-button-slick', function() {
        $('.highlights-slider').slick('slickNext');
    });

    $(document).on('click', '.prev-button-slick', function() {
        $('.highlights-slider').slick('slickPrev');
    });
}

// lightGallery instances
$(document).on('click', '[data-lg-open]', function (e) {
    e.preventDefault();

    $.ajax({
        url: '/api/get-gallery/' + $(this).data('lg-open'),
        type: 'GET'
    }).done(function(result) {
        $(this).lightGallery({
            thumbnail: true,
            dynamic: true,
            dynamicEl: JSON.parse(result)
        });
    });
});

$(document).on('click', '.single-image', function(e) {
    e.preventDefault();
     $(this).lightGallery({
        selector: 'a'
    });
});
